// React imports
import React from "react";
import { useState } from "react";

// MUI imports
import { Button } from "@mui/material";
import { MedicalServices } from "@mui/icons-material";

//Component imports
import AddCaseForm from "./AddCaseForm";

function AddCaseButton() {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        startIcon={<MedicalServices />}
        onClick={() => setOpen(true)}
      >
        Add Case
      </Button>
      {/* <Drawer anchor="right" open={open} onClose={() => setOpen(false)}> */}
      <AddCaseForm open={open} onClose={() => setOpen(false)} />
      {/* </Drawer> */}
    </React.Fragment>
  );
}

export default AddCaseButton;
