// React imports
import React from "react";

// Material-UI imports
import { Box } from "@mui/material";
import styled from "@emotion/styled";

// Local imports
import FileTable from "./FileTable";

interface FileTableCardProps {
  caseId: string;
}

const FileTableCardContainer = styled(Box)`
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  background-color: white;
  padding: 3px 20px 0 20px;
  height: calc(100vh - 200px);
`;

const FileTableCard: React.FC<FileTableCardProps> = ({ caseId }) => {
  return (
    <>
      <FileTableCardContainer>
        <FileTable caseId={caseId} />
      </FileTableCardContainer>
    </>
  );
};

export default FileTableCard;
