import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface updatedCasesTime {
  selectedItem: string;
}

const initialState: updatedCasesTime = {
  selectedItem: "",
};

const updatedCasesSlice = createSlice({
  name: "updatedCases",
  initialState,
  reducers: {
    setUpdatedCases(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload;
    },
    clearUpdatedCases(state) {
      state.selectedItem = "";
    },
  },
});

export const { setUpdatedCases, clearUpdatedCases } = updatedCasesSlice.actions;
export default updatedCasesSlice.reducer;
