// React imports
import React from "react";
import { useState } from "react";

// Redux imports
import { useDispatch } from "react-redux";
import { setUpdatedCases } from "../../redux/slices/updatedCasesSlice";

// MUI imports
import {
  AppBar,
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// API imports
import { useCreateCaseCasesPostMutation } from "../../api/jabsCentralApiPrivate";

// Interface for component props
interface AddCaseFormProps {
  open: boolean; // Indicates if the form is open
  onClose: () => void; // Function to close the form
}

// Component definition
const AddCaseForm: React.FC<AddCaseFormProps> = ({ open, onClose }) => {
  // Redux dispatch function
  const dispatch = useDispatch();

  const [createCase] = useCreateCaseCasesPostMutation(); // API call to create a case

  // State variables for form fields
  const [caseTitle, setCaseTitle] = useState("");
  const [caseType, setCaseType] = useState("");
  const [caseDescription, setCaseDescription] = useState("");
  const [caseDate, setCaseDate] = useState("");
  const [caseStatus, setCaseStatus] = useState("");

  // Function to reset form fields
  const resetFormFields = () => {
    setCaseTitle("");
    setCaseType("");
    setCaseDescription("");
    setCaseDate("");
    setCaseStatus("");
  };

  // Function to handle form close
  const handleClose = () => {
    resetFormFields();
    onClose();
  };

  // Function to handle form save
  const handleSave = () => {
    createCase({
      case: {
        title: caseTitle,
        case_type: caseType,
        case_status: caseStatus,
        description: caseDescription,
        start_date: caseDate,
        status: caseStatus,
      },
    });
    dispatch(setUpdatedCases(JSON.stringify(new Date()))); // Update the updatedCases state to trigger a refetch
    handleClose();
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, px: 6 }}>
            New Case
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={handleClose} color="inherit" sx={{ mr: 2 }}>
            CANCEL
          </Button>
          <Button color="secondary" variant="contained" onClick={handleSave}>
            Add
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: 600, py: 6, px: 8 }}>
        <Stack useFlexGap spacing={4}>
          <TextField
            label="Title"
            variant="outlined"
            value={caseTitle}
            onChange={(e) => setCaseTitle(e.target.value)}
          />
          <TextField
            label="Case Type"
            variant="outlined"
            value={caseType}
            onChange={(e) => setCaseType(e.target.value)}
          />
          <TextField
            label="Case Description"
            variant="outlined"
            value={caseDescription}
            onChange={(e) => setCaseDescription(e.target.value)}
          />
          <DatePicker
            label="Case Date"
            value={caseDate}
            onChange={(date) => setCaseDate(date ?? "")}
          />
          <FormControl variant="outlined">
            <InputLabel id="case-status-label">Case Status</InputLabel>
            <Select
              labelId="case-status-label"
              value={caseStatus}
              onChange={(e: SelectChangeEvent) => setCaseStatus(e.target.value)}
              label="Case Status"
            >
              <MenuItem value="open">Open</MenuItem>
              <MenuItem value="closed">Closed</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default AddCaseForm;
