import { createSlice } from "@reduxjs/toolkit";
// import { PatientTimeline } from "../../api/jabsCentralApiPrivate";

export interface SelectedEventState {
  selectedEvent: {
    patientId?: string | null;
    eventId?: string | null;
    eventDateTime?: string | null;
    eventType?: string | null;
    eventTitle?: string | null;
    eventDescription?: string | null;
    fileId?: string | null;
    fileDateTime?: string | null;
    fileUrl?: string | null;
    author?: string | null;
    status?: string | null;
  };
}

const initialState: SelectedEventState = {
  selectedEvent: {
    patientId: null,
    eventId: null,
    eventDateTime: null,
    eventType: null,
    eventTitle: "Title",
    eventDescription: null,
    fileId: null,
    fileDateTime: null,
    fileUrl: null,
    author: null,
    status: null,
  },
};

const selectedEventSlice = createSlice({
  name: "selectedEvent",
  initialState,
  reducers: {
    changeSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
  },
});

export const { changeSelectedEvent } = selectedEventSlice.actions;
export default selectedEventSlice.reducer;
