// React imports
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { setNewFileUrl } from "../../redux/slices/newFileUrlSlice";

// Axios import
// import axios from "axios";

// Material UI imports
import { Button, Stack } from "@mui/material";
import { AttachFile, CloudUpload } from "@mui/icons-material";
import { styled } from "@mui/system";

//API imports
import {
  GetPresignedUrlRequest,
  useGetPresignedUrlFilesPresignedUrlPostMutation,
} from "../../api/jabsCentralApiPrivate";

// Styled component for visually hidden input
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const SingleFileUploader = () => {
  const dispatch = useDispatch();

  const [file, setFile] = useState<File | null>(null);

  const selectedPatientRedux = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );
  const selectedPatientParams = useParams<{ patientId: string }>().patientId;
  const selectedPatient = selectedPatientParams || selectedPatientRedux;

  const selectedCaseRedux = String(
    useSelector((state: RootState) => state.caseSelection.selectedItem)
  );
  const selectedCaseParams = useParams<{ caseId: string }>().caseId;
  const selectedCase = selectedCaseParams || selectedCaseRedux;

  const [getPresignedUrl] = useGetPresignedUrlFilesPresignedUrlPostMutation();

  // Handler for file input change event
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]); // Set the selected file
    }
  };

  /**
   * Uploads a file to S3 using a presigned URL.
   * @param presignedUrl - The presigned S3 URL.
   */
  async function uploadFileToS3(presignedUrl: string): Promise<void> {
    if (!file) {
      console.error("No file to upload");
      return;
    }
    try {
      const response = await fetch(presignedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      if (!response.ok) {
        throw new Error(`Upload failed with status ${response.status}`);
      }
      setFile(null); // Reset the file input
      dispatch(setNewFileUrl(presignedUrl)); // track when new file is uploaded
      console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }

  // Function to upload the file using the presigned URL
  const doUpload = async (fileObject: any) => {
    if (fileObject.data) {
      const url = fileObject.data.url; // Get the presigned URL
      console.log("Presigned URL: ", url);
      console.log("File to upload: ", file);
      uploadFileToS3(url);
    }
  };

  // Handler for the upload button click event
  const handleUpload = async () => {
    if (file) {
      const fileInfo: GetPresignedUrlRequest = {
        filename: file.name,
        type: file.type,
        caseId: selectedCase,
        characterId: selectedPatient,
        description: "document",
      };
      console.log("fileInfo: ", fileInfo);
      const response = await getPresignedUrl({
        getPresignedUrlRequest: fileInfo,
      });
      if (response) {
        console.log("response: ", response);
        doUpload(response);
      }
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        {/* Title */}
        {/* <Typography variant="h4" sx={{ mb: 2 }}>
          Documents
        </Typography> */}
        {/* Stack container for the buttons */}
        <Stack direction="row" spacing={2}>
          {/* Button to attach a document */}
          <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<AttachFile />}
            sx={{ mr: 2 }}
          >
            {file && file.name}
            {!file && "Attach document"}
            <VisuallyHiddenInput onChange={handleFileChange} type="file" />
          </Button>
          {/* Button to upload the selected file */}
          {file && (
            <Button
              variant="contained"
              startIcon={<CloudUpload />}
              onClick={handleUpload}
            >
              Upload file
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default SingleFileUploader;
