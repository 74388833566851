import { ReactComponent as ThinLayer } from "../vendor/SidebarLogoThinLayer.svg";
import { ReactComponent as UCLH } from "../vendor/SidebarLogoUCLH.svg";
import { ReactComponent as NHS } from "../vendor/SidebarLogoNHS.svg";
import { ReactComponent as Ramsay } from "../vendor/SidecarLogoRamsay.svg";
import { ReactComponent as Northwell } from "../vendor/Northwell_Sidebar.svg";
import { ReactComponent as Phoenix } from "../vendor/phoenix-home-logo-sidebar.svg";
import Spire from "../vendor/spirehealthsidebar.png";
import SidelightLogo from "../vendor/SidelightLogo";

import styled from "@emotion/styled";

const ThinLayerLogo = styled(ThinLayer)`
  height: 34px;
`;
const UCLHLogo = styled(UCLH)`
  height: 34px;
`;

const NHSLogo = styled(NHS)`
  height: 36px;
`;

const RamsayLogo = styled(Ramsay)`
  height: 34px;
`;
const PhoenixLogo = styled(Phoenix)`
  height: 40px;
`;

const NorthwellLogo = styled(Northwell)`
  height: 34px;
`;

interface SidebarLogoProps {
  theme: string | null;
}

function SidebarLogo({ theme }: SidebarLogoProps) {
  //   const storedTheme = localStorage.getItem("theme");

  switch (theme) {
    case '"GREEN"':
      return <UCLHLogo />;
    case '"BLUE"':
      return <NHSLogo />;
    case '"THINLAYER"':
      return <ThinLayerLogo />;
    case '"SIDELIGHT"':
      return <SidelightLogo />;
    case '"RAMSAY"':
      return <RamsayLogo />;
    case '"PHOENIX"':
      return <PhoenixLogo />;
    case '"NORTHWELL"':
      return <NorthwellLogo />;
    case '"SPIRE"':
      return <img src={Spire} height="29px" alt="spire logo" />;
    default:
      return <SidelightLogo />;
  }
}

export default SidebarLogo;
