import { jabsCentralApiPublicBase as api } from "./jabsCentralApiPublicBase";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    statusStatusGet: build.query<
      StatusStatusGetApiResponse,
      StatusStatusGetApiArg
    >({
      query: () => ({ url: `/status` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as jabsCentralApiPublic };
export type StatusStatusGetApiResponse =
  /** status 200 Successful Response */ Status;
export type StatusStatusGetApiArg = void;
export type Status = {};
export const { useStatusStatusGetQuery } = injectedRtkApi;
