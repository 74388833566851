// import { featureFlags } from "../../config";
import { SidebarItemsType } from "../../types/sidebar";

import {
  AdminPanelSettings,
  Api,
  CloudUpload,
  Folder,
  Home,
  ManageAccounts,
  Palette,
  Person,
  Preview,
  Work,
} from "@mui/icons-material";

const homeSection = [
  {
    href: "/",
    icon: Home,
    title: "DASHBOARD",
    amount: 0,
    // category: "Dashboard",
  },
] as SidebarItemsType[];

const casesSection = [
  {
    href: "/cases",
    icon: Work,
    title: "CASES",
    children: [
      {
        href: "/cases",
        icon: Work,
        title: "All Cases",
      },
      {
        href: "/cases/case/MLC004",
        icon: Work,
        title: "Selected Case",
      },
      {
        href: "/case/MLC004",
        icon: Work,
        title: "Case Details",
      },
      {
        href: "/case/MLC004/patient/01",
        icon: Work,
        title: "Selected Patient",
      },
      {
        href: "/case/MLC004/patient/01/chronology/01",
        icon: Work,
        title: "Selected Chronology",
      },
      {
        href: "/case/MLC004/patient/01/chronology/01/event/01",
        icon: Work,
        title: "Chronology Event",
      },
      {
        href: "/case/MLC004/patient/01/event/01",
        icon: Work,
        title: "Patient Event",
      },
    ],
  },
] as SidebarItemsType[];

const filesSection = [
  {
    href: "/files",
    icon: Folder,
    title: "FILES",
    children: [
      {
        href: "/files",
        icon: Folder,
        title: "All Files",
      },
      {
        href: "/files/case/MLC004",
        icon: Folder,
        title: "Case Files",
      },
      {
        href: "/files/case/MLC004/file/01",
        icon: Folder,
        title: "Selected File",
      },
      {
        href: "/files/file/01",
        icon: Folder,
        title: "File Viewer",
      },
    ],
  },
] as SidebarItemsType[];

const adminSection = [
  {
    href: "/admin",
    icon: ManageAccounts,
    title: "ADMIN",
    children: [
      {
        href: "/admin",
        icon: ManageAccounts,
        title: "Admin Home",
      },
      {
        href: "/admin/users",
        icon: ManageAccounts,
        title: "Users",
      },
      {
        href: "/admin/users/01",
        icon: ManageAccounts,
        title: "Selected User",
      },

      {
        href: "/admin/cases",
        icon: ManageAccounts,
        title: "Cases",
      },
      {
        href: "/admin/cases/MLC004",
        icon: ManageAccounts,
        title: "Selected Case",
      },

      {
        href: "/admin/organisations",
        icon: ManageAccounts,
        title: "Organisations",
      },
      {
        href: "/admin/organisations/01",
        icon: ManageAccounts,
        title: "Selected Organisation",
      },

      {
        href: "/admin/reports",
        icon: ManageAccounts,
        title: "Reports",
      },
      {
        href: "/admin/settings",
        icon: ManageAccounts,
        title: "Settings",
      },
    ],
  },
] as SidebarItemsType[];

const superAdminSection = [
  {
    href: "/superadmin",
    icon: AdminPanelSettings,
    title: "SUPER ADMIN",
    children: [
      {
        href: "/superadmin",
        icon: AdminPanelSettings,
        title: "Super Admin Home",
      },
      {
        href: "/superadmin/patients",
        icon: AdminPanelSettings,
        title: "Patients",
      },
      {
        href: "/superadmin/patients/01",
        icon: AdminPanelSettings,
        title: "Selected Patient",
      },
      {
        href: "/superadmin/users",
        icon: AdminPanelSettings,
        title: "Users",
      },
      {
        href: "/superadmin/users/01",
        icon: AdminPanelSettings,
        title: "Selected User",
      },

      {
        href: "/superadmin/organisations",
        icon: AdminPanelSettings,
        title: "Organisations",
      },
      {
        href: "/superadmin/organisations/01",
        icon: AdminPanelSettings,
        title: "Selected Organisation",
      },
      {
        href: "/superadmin/database",
        icon: AdminPanelSettings,
        title: "Database",
      },

      {
        href: "/superadmin/settings",
        icon: AdminPanelSettings,
        title: "Settings",
      },
    ],
  },
] as SidebarItemsType[];

const screensSection = [
  {
    href: "/tests",
    icon: Preview,
    title: "TESTS",
    children: [
      {
        href: "/tests",
        icon: Palette,
        title: "Theme View",
      },
      {
        href: "/tests/upload",
        icon: CloudUpload,
        title: "Admin Upload",
      },
      {
        href: "/tests/api-test",
        icon: Api,
        title: "API Test",
      },
      {
        href: "/tests/patient-view",
        icon: Person,
        title: "Patient View",
      },
    ],
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Dashboard",
    pages: homeSection,
    custom: true,
  },
  {
    title: "Cases",
    pages: casesSection,
    custom: true,
  },
  {
    title: "Files",
    pages: filesSection,
    custom: true,
  },
  {
    title: "Admin",
    pages: adminSection,
    custom: true,
  },
  {
    title: "Super Admin",
    pages: superAdminSection,
    custom: true,
  },
  {
    title: "Tests",
    pages: screensSection,
    custom: true,
  },
];

export default navItems;
