// React imports
import React from "react";
import { useEffect } from "react";

// Redux imports
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// API imports
import { useListCaseDocumentsCasesCaseIdFilesGetQuery } from "../../api/jabsCentralApiPrivate";

// MUI imports
import {
  DataGridPro,
  // gridClasses,
  GridEventListener,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridToolbarExport,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

//local imports
import SingleFileUploader from "./SingleFileUploader";
import Spacer from "../navbar/appBar/Spacer";

// Props interface for the file table component
interface FileListProps {
  caseId: string;
}

// Custom toolbar component for the file table
const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <SingleFileUploader />
      <Spacer />
      <GridToolbarQuickFilter />
      <GridToolbarExport />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
};

const FileTable: React.FC<FileListProps> = ({ caseId }) => {
  // Listen for new file added from the Redux store
  const updatedFiles = useSelector((state: RootState) => state.newFileUrl);

  // Fetch list of cases
  const {
    data: listFiles,
    error: listFilesError,
    isLoading: isListFilesLoading,
    // isFetching: isListFilesFetching,
    refetch: refetchListFiles,
  } = useListCaseDocumentsCasesCaseIdFilesGetQuery(
    {
      caseId: caseId,
    },
    {
      pollingInterval: 30000,
    }
  );

  // Handle row click event to navigate to the case details page and set the selected case in the Redux store
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    console.log("Row clicked: ", params);
  };

  // Refetch the list of files when a new file is added
  useEffect(() => {
    if (updatedFiles) {
      refetchListFiles();
    }
  }, [updatedFiles, refetchListFiles]);

  let content = null;

  // Display loader while data is loading or fetching
  if (isListFilesLoading) {
    content = (
      <DataGridPro
        loading
        columns={[{ field: "id", headerName: "Loading...", width: 150 }]}
        slotProps={{
          loadingOverlay: {
            variant: "skeleton",
            noRowsVariant: "skeleton",
          },
        }}
      />
    );
  } else if (listFilesError) {
    // Display error message if there is an error fetching data
    console.log("listFilesError: ", listFilesError);
    content = <div>{JSON.stringify(listFilesError)}</div>;
  } else {
    if (!listFiles) {
      // Display message if no files are found
      content = <div>No files available for this case</div>;
    } else {
      // Display case files data
      content = (
        <DataGridPro
          onRowClick={handleRowClick}
          disableColumnSelector // Disable the column selector
          disableDensitySelector // Disable the density selector
          checkboxSelection
          disableRowSelectionOnClick // Disable row selection on click
          slots={{
            toolbar: CustomToolbar,
          }}
          sx={{
            pt: 1,
            border: "none",
            backgroundColor: "white",
            //   [`& .${gridClasses.cell}`]: {
            //     pt: 3,
            //     pb: 2,
            //   },
          }}
          initialState={{
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
            }, // Set the pinned columns
            sorting: { sortModel: [{ field: "filename", sort: "asc" }] }, // Set the sorting column
            density: "standard", // Set the density to standard
          }}
          rows={listFiles?.data ?? []}
          columns={[
            { field: "id", headerName: "ID", width: 200 },
            { field: "filename", headerName: "Name", flex: 1, minWidth: 150 },
            {
              field: "description",
              headerName: "Description",
              flex: 1,
              minWidth: 150,
            },
            { field: "url", headerName: "URL", flex: 1, minWidth: 150 },
            { field: "type", headerName: "Type", width: 130 },
            {
              field: "createdAt",
              headerName: "Created At",
              flex: 1,
              minWidth: 150,
            },
            {
              field: "updatedAt",
              headerName: "Updated At",
              flex: 1,
              minWidth: 150,
            },
          ]}
        />
      );
    }
  }

  return <React.Fragment>{content}</React.Fragment>;
};

export default FileTable;
