// react imports
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCase } from "../../redux/slices/selectedCaseSlice";
import { RootState } from "../../redux/store";

// MUI imports
import {
  DataGridPro,
  // gridClasses,
  GridEventListener,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

// API imports
import { useListCasesCasesGetQuery } from "../../api/jabsCentralApiPrivate";

function CaseTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selector to get the updated cases from the Redux store
  const updatedCases = useSelector((state: RootState) => state.updatedCases);

  // Fetch the list of cases using the custom hook
  const {
    data: listCases,
    error: listCasesError,
    isLoading: isListCasesLoading,
    isFetching: isListCasesFetching,
    refetch: refetchListCases,
  } = useListCasesCasesGetQuery({
    status: "active",
  });

  // Handle row click event to navigate to the case details page and set the selected case in the Redux store
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate(`/case/${params.id}`);
    dispatch(setSelectedCase(params.id.toString()));
  };

  // Refetch the list of cases when the updatedCases changes
  useEffect(() => {
    refetchListCases();
  }, [updatedCases, refetchListCases, dispatch]);

  let content = null;

  // Display loader while data is loading or fetching
  if (isListCasesLoading || isListCasesFetching) {
    content = (
      <DataGridPro
        loading
        columns={[{ field: "id", headerName: "Loading...", width: 150 }]}
        slotProps={{
          loadingOverlay: {
            variant: "skeleton",
            noRowsVariant: "skeleton",
          },
        }}
      />
    );
  } else if (listCasesError) {
    // Display error message if there is an error fetching data
    content = <div>{JSON.stringify(listCasesError)}</div>;
  } else {
    if (!listCases) {
      // Display message if no cases are found
      content = <div>!Listcases</div>;
    } else {
      // Display the list of cases in a DataGridPro component
      content = (
        <DataGridPro
          onRowClick={handleRowClick}
          disableColumnSelector // Disable the column selector
          disableDensitySelector // Disable the density selector
          checkboxSelection
          disableRowSelectionOnClick // Disable row selection on click
          slots={{ toolbar: GridToolbar }} // Set the toolbar slot to GridToolbar
          slotProps={{
            toolbar: {
              showQuickFilter: true, // Show the quick filter in the toolbar
            },
          }}
          sx={{
            pt: 1,
            border: "none",
            backgroundColor: "white",
            //   [`& .${gridClasses.cell}`]: {
            //     pt: 3,
            //     pb: 2,
            //   },
          }}
          initialState={{
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
            }, // Set the pinned columns
            sorting: { sortModel: [{ field: "start_date", sort: "desc" }] }, // Set the sorting column
            density: "comfortable", // Set the density to comfortable
          }}
          rows={listCases?.data ?? []} // Set the rows of the DataGridPro to the list of cases
          columns={[
            // Define the columns for the DataGridPro
            // {
            //   field: "id",
            //   headerName: "Case ID",
            //   width: 210,
            // },
            {
              field: "start_date",
              headerName: "Start Date",
              width: 150,
              type: "date",
              valueFormatter: (params) => {
                return params ? new Date(params).toLocaleDateString() : "N/A";
              },
            },
            {
              field: "title",
              headerName: "Title",
              flex: 1,
              minWidth: 150,
            },
            {
              field: "description",
              headerName: "Description",
              flex: 2,
              minWidth: 150,
            },
            {
              field: "case_status",
              headerName: "Status",
              width: 120,
            },
            {
              field: "case_type",
              headerName: "Type",
              width: 120,
            },
            {
              field: "created_at",
              headerName: "Date Created",
              width: 150,
              type: "date",
              valueFormatter: (params) => {
                return params ? new Date(params).toLocaleDateString() : "N/A";
              },
            },
          ]}
        />
      );
    }
  }

  return <React.Fragment>{content}</React.Fragment>;
}

export default CaseTable;
