// React imports
import React from "react";

// Material-UI imports
import { Box, Card, Skeleton } from "@mui/material";

// Component imports
import CardTitle from "../Card/CardTitle";

// Props for the CardDetailsLoading component
interface CardDetailsLoadingProps {
  title: string;
  lines?: number;
}

const LoadingItem = () => {
  return (
    <Box sx={{ display: "flex", pb: 2 }}>
      <Box sx={{ width: "110px", flexShrink: 0, pr: 3 }}>
        <Skeleton variant="text" />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Skeleton variant="text" />
      </Box>
    </Box>
  );
};

// Component to display loading skeletons for card details
const CardDetailsLoading: React.FC<CardDetailsLoadingProps> = ({
  title,
  lines = 5,
}) => {
  return (
    <Card sx={{ maxWidth: "440px", mb: 5 }}>
      <CardTitle title={title} />
      <Box sx={{ px: 5, py: 7 }}>
        {/* Skeleton for card details */}
        {Array.from({ length: lines }).map((_, index) => (
          <LoadingItem key={index} />
        ))}
      </Box>
    </Card>
  );
};

export default CardDetailsLoading;
