import React from "react";
import styled from "@emotion/styled";

import { Box, AppBar as MuiAppBar, Stack, Typography } from "@mui/material";

import NavbarUserDropdown from "../NavbarUserDropdown";
import { useTheme } from "@emotion/react"; // Import the useTheme hook
import { useMediaQuery } from "@mui/material"; // Import the useMediaQuery hook

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: rgba(50, 50, 93, 0.025) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px -1px;
  position: sticky;
`;

type NavbarProps = {
  title?: string;
  leftTools?: React.ReactNode;
  children?: React.ReactNode;
  rightTools?: React.ReactNode;
  breakpointLeft?: string;
  breakpointChildren?: string;
  breakpointRight?: string;
  mobileHideRight?: boolean;
  mobileHideLeft?: boolean;
  mobileHideChildren?: boolean;
};
type PageToolbarContainerProps = {
  children: React.ReactNode;
};
type TitleProps = {
  title?: string;
};
type ResponsiveToolsProps = {
  children: React.ReactNode;
  breakpoint?: string;
  hideMobile?: boolean;
};

const Spacer = () => {
  return <Box sx={{ flexGrow: 1 }} />;
};

const PageToolbarContainer: React.FC<PageToolbarContainerProps> = ({
  children,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      sx={{
        minHeight: 64,
        ml: { xs: 4, sm: 6 },
        pr: { xs: 1, sm: 3 },
      }}
    >
      {children}
    </Stack>
  );
};

const PageToolbar: React.FC<PageToolbarContainerProps> = ({ children }) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      flexWrap="wrap"
      useFlexGap
      spacing={5}
      sx={{ width: "100%", py: 2 }}
    >
      {children}
    </Stack>
  );
};

const Title: React.FC<TitleProps> = ({ title }) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box sx={{ minHeight: 48, display: "flex", alignItems: "center" }}>
      <Typography
        sx={{ lineHeight: 1, pr: 2 }}
        variant={isSmUp ? "h3" : "h4"}
        color="WindowText"
      >
        {title}
      </Typography>
    </Box>
  );
};

const LeftTools: React.FC<ResponsiveToolsProps> = ({
  children,
  breakpoint,
  hideMobile,
}) => {
  let display = {};
  if (hideMobile && !breakpoint) {
    display = { xs: "none", sm: "flex" };
  } else if (breakpoint) {
    display = { xs: "none", [breakpoint]: "flex" };
  } else {
    display = { xs: "flex" };
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ display: display, minHeight: 48 }}
    >
      {children}
    </Stack>
  );
};

const ChildTools: React.FC<ResponsiveToolsProps> = ({
  children,
  breakpoint,
}) => {
  const display = breakpoint
    ? { xs: "none", [breakpoint]: "flex" }
    : { xs: "flex" };
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ display: display, flexGrow: 1, minHeight: 48 }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

const RightTools: React.FC<ResponsiveToolsProps> = ({
  children,
  breakpoint,
  hideMobile,
}) => {
  let display = {};
  if (hideMobile && !breakpoint) {
    display = { xs: "none", sm: "flex" };
  } else if (breakpoint) {
    display = { xs: "none", [breakpoint]: "flex" };
  } else {
    display = { xs: "flex" };
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ display: display, minHeight: 48 }}
    >
      {children}
    </Stack>
  );
};

const SidelightPageBar: React.FC<NavbarProps> = ({
  title,
  leftTools,
  children,
  rightTools,
  breakpointLeft,
  breakpointChildren,
  breakpointRight,
  mobileHideRight,
  mobileHideLeft,
  mobileHideChildren,
}) => {
  // const theme = useTheme();

  let displayRight = {};
  if (breakpointRight && mobileHideRight) {
    displayRight = { xs: "none", sm: "flex", [breakpointRight]: "none" };
  } else if (breakpointRight) {
    displayRight = { xs: "flex", [breakpointRight]: "none" };
  } else {
    displayRight = { xs: "none" };
  }

  let displayLeft = {};
  if (breakpointLeft && mobileHideLeft) {
    displayLeft = { xs: "none", sm: "flex", [breakpointLeft]: "none" };
  } else if (breakpointLeft) {
    displayLeft = { xs: "flex", [breakpointLeft]: "none" };
  } else {
    displayLeft = { xs: "none" };
  }

  let displayChildren = {};
  if (breakpointChildren && mobileHideChildren) {
    displayChildren = { xs: "none", sm: "flex", [breakpointChildren]: "none" };
  } else if (breakpointChildren) {
    displayChildren = { xs: "flex", [breakpointChildren]: "none" };
  } else {
    displayChildren = { xs: "none" };
  }

  return (
    <React.Fragment>
      <AppBar position="sticky">
        <PageToolbarContainer>
          <PageToolbar>
            <Title title={title} />
            {leftTools && (
              <LeftTools
                breakpoint={breakpointLeft}
                hideMobile={mobileHideLeft}
              >
                {leftTools}
              </LeftTools>
            )}

            <ChildTools
              breakpoint={breakpointChildren}
              hideMobile={mobileHideChildren}
            >
              {children}
            </ChildTools>

            {rightTools && (
              <RightTools
                breakpoint={breakpointRight}
                hideMobile={mobileHideRight}
              >
                {rightTools}
              </RightTools>
            )}
          </PageToolbar>
          <Stack direction="row" alignItems="flex-start" sx={{ pt: 0, ml: 2 }}>
            <NavbarUserDropdown />
          </Stack>
        </PageToolbarContainer>
      </AppBar>

      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        flexWrap="wrap"
        useFlexGap
        spacing={2}
        sx={{ width: "100%", px: 6, pt: 2 }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            display: displayLeft,
            borderBottom: "1px solid #e0e0e0",
            pb: 2,
            mb: 2,
          }}
        >
          {leftTools}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{
            display: displayChildren,
            borderBottom: "1px solid #e0e0e0",
            pb: 2,
            mb: 2,
          }}
        >
          {children}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            display: displayRight,
            borderBottom: "1px solid #e0e0e0",
            pb: 2,
            mb: 2,
          }}
        >
          {rightTools}
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export { Spacer };

export default SidelightPageBar;
