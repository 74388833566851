import React from "react";
import { Helmet } from "react-helmet-async";
import { Box, Grid } from "@mui/material";
import styled from "@emotion/styled";
// import PatientApiTester from "../../components/apiTesting/PatientApiTester";
// import PatientList from "../../components/admin/PatientList";
import TLPagebar from "../../components/navbar/appBar/TLPageBar";

const PageContent = styled(Box)`
  padding: 12px 24px 24px 24px;
`;

function ApiTestPagePage() {
  return (
    <React.Fragment>
      <Helmet title="Api Test Page" />
      <TLPagebar title="Api Test Page" />
      <PageContent>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6} md={5} lg={4} xl={4} xxl={4}>
            {/* <PatientList /> */}
          </Grid>
          <Grid item xs={12} sm={6} md={7} lg={8} xl={8} xxl={8}>
            {/* <PatientApiTester /> */}
          </Grid>
        </Grid>
      </PageContent>
    </React.Fragment>
  );
}

export default ApiTestPagePage;
