// React imports
import React from "react";
import { useParams } from "react-router-dom";

// Material-UI imports
import { Box } from "@mui/material";

// Local imports
import CharacterDetailsCard from "../../../components/characterList/CharacterDetailsCard";
import CardDetailsLoading from "../../../components/loaders/CardDetailsLoading";
import CaseDetailsCard from "../../../components/caseList/CaseDetailsCard";

// data imports
import {
  useGetCharacterCharactersCharacterIdGetQuery,
  Character,
  useGetCaseCasesCaseIdGetQuery,
  Case,
} from "../../../api/jabsCentralApiPrivate";

const SelectedPatientDetailsPanel: React.FC = () => {
  // get case and patient ids from URL
  const selectedCase = useParams<{ caseId: string }>().caseId;
  const selectedPatient = useParams<{ patientId: string }>().patientId;

  // fetch character  data
  const {
    data: characterData,
    isLoading: isCharacterLoading,
    isFetching: isCharacterFetching,
    error: characterError,
  } = useGetCharacterCharactersCharacterIdGetQuery({
    characterId: selectedPatient || "",
  });

  // fetch case data
  const {
    data: caseData,
    isLoading: isCaseLoading,
    isFetching: isCaseFetching,
    error: caseError,
  } = useGetCaseCasesCaseIdGetQuery({
    caseId: selectedCase || "",
  });

  let characterContent = (
    <CardDetailsLoading title="Loading Character..." lines={5} />
  );
  if (characterData) {
    characterContent = (
      <CharacterDetailsCard characterData={characterData as Character} />
    );
  } else if (characterError) {
    characterContent = <div>{JSON.stringify(characterError)}</div>;
  } else if (isCharacterLoading || isCharacterFetching) {
    characterContent = (
      <CardDetailsLoading title="Loading Character..." lines={5} />
    );
  }

  let caseContent = <CardDetailsLoading title="Loading Case..." lines={6} />;
  if (caseData) {
    caseContent = <CaseDetailsCard caseData={caseData as Case} />;
  } else if (isCaseLoading || isCaseFetching) {
    caseContent = <CardDetailsLoading title="Loading Case..." lines={6} />;
  } else if (caseError) {
    caseContent = <div>{JSON.stringify(caseError)}</div>;
  }

  return (
    <Box>
      {characterContent}
      {caseContent}
    </Box>
  );
};

export default SelectedPatientDetailsPanel;
