// React imports
import React from "react";
import { Helmet } from "react-helmet-async";

// Material-UI imports
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import styled from "@emotion/styled";

// Local imports
import SidelightPageBar from "../../components/navbar/appBar/SidelightPageBar";
import CaseTableCard from "../../components/caseList/CaseTableCard";
import AddCaseButton from "../../components/caseList/AddCaseButton";
// import GetCases from "../../components/apiTesting/Cases/GetCases";

const PageContentContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 5px 25px 25px 25px;
`;

const CasesPage: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet title="Cases" />
      <SidelightPageBar title="Cases" rightTools={<AddCaseButton />} />
      <PageContentContainer>
        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 3 }}>
          <Link color="inherit" href="/">
            Dashboard
          </Link>
          <Typography color="primary">Cases</Typography>
        </Breadcrumbs>
        <CaseTableCard />
        {/* <GetCases /> */}
      </PageContentContainer>
    </React.Fragment>
  );
};

export default CasesPage;
