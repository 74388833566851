// React imports
import React from "react";

// Material-UI imports
import { Box, Typography } from "@mui/material";

interface CardInfoItemProps {
  label: string;
  value: string | null | undefined;
  labelWidth?: string;
}

const CardInfoItem: React.FC<CardInfoItemProps> = ({
  label,
  value,
  labelWidth = 110,
}) => {
  return (
    <Box sx={{ display: "flex", pb: 2 }}>
      <Box sx={{ width: `${labelWidth}px`, flexShrink: 0, pr: 2 }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: 700, textAlign: "right" }}
        >
          {label}:
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">{value}</Typography>
      </Box>
    </Box>
  );
};

export default CardInfoItem;
