import React from "react";
import { Helmet } from "react-helmet-async";
// import DataFetcher from "./PatientListTest";
import TLPagebar from "../../components/navbar/appBar/TLPageBar";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
// import PatientList from "../../components/admin/PatientList";
// import PatientFileUploader from "../../components/admin/patientFileUploader/PatientFileUploader";

import { Grid } from "@mui/material";

const PageContent = styled(Box)`
  padding: 12px 24px 24px 24px;
`;

function AdminUploadPage() {
  return (
    <React.Fragment>
      <Helmet title="Admin Upload Page" />

      <TLPagebar title="Admin Upload Page" />
      <PageContent>
        <Grid container spacing={6} columns={24}>
          <Grid item xs={24} sm={24} md={11} lg={9} xl={9} xxl={9}>
            {/* <PatientList /> */}
          </Grid>
          <Grid item xs={24} sm={24} md={13} lg={15} xl={15} xxl={15}>
            {/* <PatientFileUploader /> */}
          </Grid>
        </Grid>
      </PageContent>
    </React.Fragment>
  );
}

export default AdminUploadPage;
