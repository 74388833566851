import React from "react";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import Legal from "./layouts/Legal";
import Sidelight from "./layouts/Scratch";

// blank page
import Blank from "./pages/Blank";

//auth pages
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import AuthGuard from "./components/guards/AuthGuard";

// user pages
import CasesPage from "./pages/user/CasesPage";
import DashboardPage from "./pages/user/DashboardPage";
import SelectedCasePage from "./pages/user/SelectedCasePage";
import SelectedPatientPage from "./pages/user/SelectedPatientPage";

// admin pages
import OrganisationsPage from "./pages/admin/OrganisationsPage";
import UsersPage from "./pages/admin/UsersPage";

// test pages
import AdminUploadPage from "./pages/test/AdminUploadPage";
import ApiTestPage from "./pages/test/ApiTestPage";
import ThemeView from "./pages/test/ThemeView";

const routes = [
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <Sidelight />
      </AuthGuard>
    ),
    children: [
      //Will contain a case selector and also some kind of aggregated dashboard
      {
        path: "/",
        element: <DashboardPage />,
      },
      //A list of cases with the ability to select a particular case and show details and navigate.
      {
        path: "/cases",
        element: <CasesPage />,
      },
      // Case list page with details of a particular case shown
      {
        path: "/cases/case/:caseId",
        element: <CasesPage />,
      },
      // Contains the ability to open up patient(s) timeline and fact chronologies.
      {
        path: "/case/:caseId",
        element: <SelectedCasePage />,
      },
      // Contains detailed information about the selected patient within the selected case, including patient timeline and fact chronologies.
      {
        path: "/case/:caseId/patient/:patientId",
        element: <SelectedPatientPage />,
      },
      // For deep linking to a particular chronology.
      {
        path: "/case/:caseId/patient/:patientId/chronology/:chronologyId",
        element: <Blank />,
      },
      // For deep linking to a particular event in a chronology.
      {
        path: "/case/:caseId/patient/:patientId/chronology/:chronologyId/event/:eventId",
        element: <Blank />,
      },
      // or deep linking to a particular event in the patient timeline.
      {
        path: "/case/:caseId/patient/:patientId/event/:eventId",
        element: <Blank />,
      },
      // Choose which case you want to see files for.
      {
        path: "/files",
        element: <Blank />,
      },
      // Shows the files uploaded in the case.
      {
        path: "/files/case/:caseId",
        element: <Blank />,
      },
      // The files page with a file viewer open
      {
        path: "/files/case/:caseId/file/:fileId",
        element: <Blank />,
      },
      // Dedicated file viewer page in it's own tab - for deep linking and in general use
      {
        path: "/file/:fileId",
        element: <Blank />,
      },
      // Contains user profile information and settings.
      {
        path: "/profile",
        element: <Blank />,
      },
    ],
  },
  {
    path: "/admin",
    element: (
      <AuthGuard>
        <Sidelight />
      </AuthGuard>
    ),
    children: [
      {
        path: "/admin",
        element: <Blank />,
      },
      {
        path: "/admin/users",
        element: <UsersPage />,
      },
      {
        path: "/admin/users/:userId",
        element: <UsersPage />,
      },
      {
        path: "/admin/cases",
        element: <Blank />,
      },
      {
        path: "/admin/cases/:caseId",
        element: <Blank />,
      },
      {
        path: "/admin/organisations",
        element: <OrganisationsPage />,
      },
      {
        path: "/admin/organisations/:organisationId",
        element: <OrganisationsPage />,
      },
      {
        path: "/admin/reports",
        element: <Blank />,
      },
      {
        path: "/admin/settings",
        element: <Blank />,
      },
    ],
  },
  {
    path: "/superadmin",
    element: (
      <AuthGuard>
        <Sidelight />
      </AuthGuard>
    ),
    children: [
      {
        path: "/superadmin",
        element: <Blank />,
      },
      {
        path: "/superadmin/patients",
        element: <Blank />,
      },
      {
        path: "/superadmin/patients/:patientId",
        element: <Blank />,
      },
      {
        path: "/superadmin/organisations",
        element: <Blank />,
      },
      {
        path: "/superadmin/organisations/:organisationId",
        element: <Blank />,
      },
      {
        path: "/superadmin/users",
        element: <Blank />,
      },
      {
        path: "/superadmin/users/:userId",
        element: <Blank />,
      },
      {
        path: "/superadmin/database",
        element: <Blank />,
      },
      {
        path: "/superadmin/settings",
        element: <Blank />,
      },
    ],
  },
  {
    path: "/tests",
    element: (
      <AuthGuard>
        <Legal />
      </AuthGuard>
    ),
    children: [
      {
        path: "/tests",
        element: <ThemeView />,
      },
      {
        path: "/tests/upload",
        element: <AdminUploadPage />,
      },
      {
        path: "/tests/:id",
        element: <AdminUploadPage />,
      },
      {
        path: "/tests/api-test",
        element: <ApiTestPage />,
      },
      {
        path: "/tests/api-test/:id",
        element: <ApiTestPage />,
      },
    ],
  },
];

export default routes;
