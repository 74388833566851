// React imports
import React from "react";

// Material-UI imports
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

// Local imports
import CaseTable from "./CaseTable";

interface CaseTableCardProps {
  title?: string;
  icon?: React.ReactNode;
}

const CaseCardContainer = styled(Box)`
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  background-color: white;
  padding: 10px 20px 0 20px;
  height: calc(100vh - 200px);
`;

const CaseTableCard: React.FC<CaseTableCardProps> = ({ title, icon }) => {
  return (
    <CaseCardContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">{title}</Typography>
        {icon}
      </Box>
      <CaseTable />
    </CaseCardContainer>
  );
};

export default CaseTableCard;
