// React imports
import React from "react";
import { useParams } from "react-router-dom";

// Material-UI imports
import { Box } from "@mui/material";

// Local imports

// import GetCaseFiles from "../../../components/apiTesting/Files/GetCaseFiles";
// import FileTable from "../../../components/files/FileTable";
import FileTableCard from "../../../components/files/FileTableCard";

const SelectedPatientFilesPanel: React.FC = () => {
  // get case and patient ids from URL
  const selectedCase = useParams<{ caseId: string }>().caseId;

  return (
    <Box>
      <FileTableCard caseId={selectedCase || ""} />
      {/* <GetCaseFiles caseId={selectedCase || ""} />; */}
    </Box>
  );
};

export default SelectedPatientFilesPanel;
