// React imports
import React from "react";

// Material-UI imports
import { Box, Card } from "@mui/material";

//API imports
import { Character } from "../../api/jabsCentralApiPrivate";

// Component imports
import CardTitle from "../Card/CardTitle";
import CardInfoItem from "../Card/CardInfoItem";

interface CharacterDetailsCardProps {
  characterData: Character;
}

const CharacterDetailsCard: React.FC<CharacterDetailsCardProps> = ({
  characterData,
}) => {
  return (
    <Card sx={{ maxWidth: "440px", mb: 5 }}>
      <CardTitle title="Character" />
      <Box sx={{ px: 5, py: 7 }}>
        {/* Character Id */}
        <CardInfoItem label="Character Id" value={characterData.id} />

        {/* Type*/}
        <CardInfoItem label="Type" value={characterData.character_type} />

        {/* Name */}
        <CardInfoItem
          label="Name"
          value={`${characterData.first_name} ${characterData.last_name}`}
        />
        {/* DOB */}
        <CardInfoItem label="DOB" value={characterData.date_of_birth} />
        {/* Gender */}
        <CardInfoItem label="Gender" value={characterData.gender} />
      </Box>
    </Card>
  );
};

export default CharacterDetailsCard;
