// React imports
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

// redux imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { setSelectedCase } from "../../redux/slices/selectedCaseSlice";

// Material-UI imports
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import styled from "@emotion/styled";

// Local imports
import CardDetailsLoading from "../../components/loaders/CardDetailsLoading";
import CaseDetailsCard from "../../components/caseList/CaseDetailsCard";
import CaseSelector from "../../components/caseList/CaseSelector";
import PatientListCard from "../../components/characterList/PatientListCard";
import SidelightPageBar from "../../components/navbar/appBar/SidelightPageBar";

//data imports
import {
  useGetCaseCasesCaseIdGetQuery,
  Case,
} from "../../api/jabsCentralApiPrivate";

const PageContentContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 5px 25px 25px 25px;
`;

const SelectedCasePage: React.FC = () => {
  const dispatch = useDispatch();

  const [caseTitle, setCaseTitle] = useState("");
  // Get the selected case from the Redux store
  const selectedCaseRedux = String(
    useSelector((state: RootState) => state.caseSelection.selectedItem)
  );
  // Get the selected case from the URL
  const selectedCase = useParams<{ caseId: string }>().caseId;
  // If the URL has a value, update the Redux store to match the URL value
  if (selectedCase !== selectedCaseRedux && selectedCase) {
    dispatch(setSelectedCase(selectedCase));
  }
  // const selectedCaseData = caseListData.find((c) => c.case_id === selectedCase);

  // Fetch case data
  let content = null;
  const {
    data: caseData,
    error: caseError,
    isLoading: isCaseLoading,
    isFetching: isCaseFetching,
  } = useGetCaseCasesCaseIdGetQuery({ caseId: selectedCase || "" });

  useEffect(() => {
    if (caseData) {
      setCaseTitle(caseData.title); // Set the case title from the fetched data
    }
  }, [caseData]);

  // Display loader while data is loading
  if (isCaseLoading) {
    content = <CardDetailsLoading title="Loading Case Details..." />;
  } else if (isCaseFetching) {
    // Display loader while data is fetching
    content = <CardDetailsLoading title="Loading Case Details..." />;
  } else if (caseError) {
    // Display error message if there is an error fetching data
    console.log("caseError: ", caseError);
    content = <div>{JSON.stringify(caseError)}</div>;
  } else {
    if (!caseData) {
      // Display message if no case is selected
      content = <div>!caseData</div>;
    } else {
      // Display case data
      content = <CaseDetailsCard caseData={caseData as Case} />;
    }
  }

  return (
    <React.Fragment>
      <Helmet title={caseTitle} />
      <SidelightPageBar
        title={caseTitle}
        breakpointRight="md"
        rightTools={<CaseSelector />}
      />
      <PageContentContainer>
        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 3 }}>
          <Link color="inherit" href="/">
            Dashboard
          </Link>
          <Link color="inherit" href="/cases">
            Cases
          </Link>
          <Typography color="primary">{caseTitle}</Typography>
        </Breadcrumbs>

        {content}
        <PatientListCard caseId={selectedCase || ""} />
      </PageContentContainer>
    </React.Fragment>
  );
};

export default SelectedCasePage;
