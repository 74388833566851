// react imports
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//MUI imports
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { PersonAdd } from "@mui/icons-material";

//Component imports
import AddPatientForm from "./AddPatientForm";
import CardDetailsLoading from "../loaders/CardDetailsLoading";
import CardTitle from "../Card/CardTitle";
// import Loader from "../loaders/Loader";

//API imports
import { useListCaseCharactersCasesCaseIdCharactersGetQuery } from "../../api/jabsCentralApiPrivate";

interface PatientListCardProps {
  caseId: string;
}
//Component definition

const PatientListCard: React.FC<PatientListCardProps> = ({ caseId }) => {
  const [open, setOpen] = useState(false); // Indicates if the new patient form is open
  const navigate = useNavigate();

  // Function to handle form close
  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      refetchListPatients();
    }, 500); // Wait for 500ms before refetching the list of patients
  }

  // Handle navigation when clicking on a patient

  function handlePatientClick(patientId: string) {
    navigate(`/case/${caseId}/patient/${patientId}`);
  }

  // Fetch list of patients
  const {
    data: listPatients,
    error: listPatientsError,
    isLoading: isListPatientsLoading,
    isFetching: isListPatientsFetching,
    refetch: refetchListPatients,
  } = useListCaseCharactersCasesCaseIdCharactersGetQuery({
    caseId: caseId,
  });

  let content = null;

  // Display loader while data is loading
  if (isListPatientsLoading || isListPatientsFetching) {
    content = <CardDetailsLoading title="Loading Patients..." lines={2} />;
  } else if (listPatientsError) {
    // Display error message if there is an error fetching data
    content = <div>{JSON.stringify(listPatientsError)}</div>;
  } else {
    if (!listPatients) {
      // Display message if no patient is selected
      content = <div>!listPatients</div>;
    } else {
      // Display patient files data
      content = (
        <List>
          {listPatients?.data?.map((patient) => (
            <ListItem key={patient.id}>
              <ListItemButton
                onClick={() => patient.id && handlePatientClick(patient.id)}
              >
                <Typography variant="h6">
                  {patient.first_name} {patient.last_name}
                  {" - dob: "}
                  {patient.date_of_birth}
                </Typography>
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem>
            <ListItemButton onClick={() => setOpen(true)}>
              <ListItemIcon>
                <PersonAdd />
              </ListItemIcon>
              <Typography variant="h6">Add Patient</Typography>
            </ListItemButton>
          </ListItem>
        </List>
      );
    }
  }

  return (
    <React.Fragment>
      <Card sx={{ maxWidth: "440px", mb: 5 }}>
        <CardTitle title="Patients" />
        {content}
      </Card>

      <AddPatientForm open={open} onClose={handleClose} caseId={caseId} />
    </React.Fragment>
  );
};

export default PatientListCard;
