// React imports
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

// Material-UI imports
import styled from "@emotion/styled";
import { useMediaQuery, useTheme } from "@mui/material";
import { Box, SpeedDial, SpeedDialAction, Typography } from "@mui/material";
import {
  AdminPanelSettings,
  Folder,
  Home,
  ManageAccounts,
  Menu,
  Work,
} from "@mui/icons-material";

// Custom components
import useAuth from "../../hooks/useAuth";
import Spacer from "../navbar/appBar/Spacer";
import SidelightFooterLogo from "../../vendor/SidelightFooterLogo";

const breakpoint = "md";

const Wrapper = styled(Box)`
  flex-shrink: 0;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;

  background-color: ${(props) => props.theme.palette.grey[900]};
`;

const DesktopNavLinkContainer = styled(Box)`
  display: flex;
  padding: 5px 5px;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.grey[500]};
  border-bottom: 2px solid transparent;
  &:hover {
    color: ${(props) => props.theme.palette.secondary.dark};
    background: linear-gradient(
      0deg,
      ${(props) => props.theme.palette.grey[800]} 0%,
      ${(props) => props.theme.palette.grey[900]} 50%
    );
  }
  .active & {
    color: ${(props) => props.theme.palette.secondary.light};
    border-color: ${(props) => props.theme.palette.secondary.main};
  }
`;
const LinkText = styled(Typography)`
  font-size: 0.8rem;
  font-weight: 700;
  color: ${(props) => props.theme.palette.grey[200]};
  text-transform: uppercase;
  .active & {
    color: white;
  }
`;

const LogoContainer = styled(Box)`
  width: 195px;
  padding-right: 5px;
  display: flex;
  align-items: center;
`;

const navItems = [
  {
    label: "Dashboard",
    icon: <Home />,
    path: "/",
    role: "user",
  },
  {
    label: "Cases",
    icon: <Work />,
    path: "/cases",
    role: "user",
  },
  {
    label: "Files",
    icon: <Folder />,
    path: "/files",
    role: "user",
  },
  // {
  //   label: "Admin",
  //   icon: <ManageAccounts />,
  //   path: "/admin",
  //   role: "admin",
  // },
  // {
  //   label: "Super Admin",
  //   icon: <AdminPanelSettings />,
  //   path: "/superadmin",
  //   role: "superadmin",
  // },
];

const Footer = () => {
  const { user } = useAuth();
  const isAdmin = user?.role === "admin" || user?.role === "superadmin";
  const isSuperAdmin = user?.role === "superadmin";
  const navigate = useNavigate();
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(true);
  };
  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(breakpoint));
  if (isMobile) {
    return (
      <Wrapper>
        <LogoContainer>
          <SidelightFooterLogo />
          {/* <img src={logo} alt="Sidelight logo" /> */}
        </LogoContainer>
        <Spacer />
        <SpeedDial
          ariaLabel="Mobile navigation"
          sx={{ position: "absolute", bottom: 5, right: 15 }}
          direction="up"
          icon={<Menu />}
          FabProps={{ size: "medium" }}
          onClose={handleCloseSpeedDial}
          onOpen={handleOpenSpeedDial}
          open={openSpeedDial}
        >
          {user &&
            navItems.map((navItem) => (
              <SpeedDialAction
                key={navItem.label}
                icon={navItem.icon}
                tooltipTitle={navItem.label}
                onClick={() => handleNavigation(navItem.path)}
              />
            ))}
          {isAdmin && (
            <SpeedDialAction
              key="Admin"
              icon={<ManageAccounts />}
              tooltipTitle="Admin"
              onClick={() => handleNavigation("/admin")}
            />
          )}

          {isSuperAdmin && (
            <SpeedDialAction
              key="Super Admin"
              icon={<AdminPanelSettings />}
              tooltipTitle="Super Admin"
              onClick={() => handleNavigation("/superadmin")}
            />
          )}
        </SpeedDial>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <LogoContainer>
          <SidelightFooterLogo />
        </LogoContainer>
        <Spacer />
        <Box sx={{ display: "flex", gap: 10, marginRight: 10, marginLeft: 10 }}>
          {navItems.map((navItem) => (
            <NavLink
              to={navItem.path}
              style={{ color: "white", textDecoration: "none" }}
            >
              <DesktopNavLinkContainer key={navItem.label}>
                {navItem.icon}
                <LinkText>{navItem.label}</LinkText>
              </DesktopNavLinkContainer>
            </NavLink>
          ))}
        </Box>
      </Wrapper>
    );
  }
};
export default Footer;
