import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectionPatientId {
  selectedItem: string | null;
}

const initialState: SelectionPatientId = {
  selectedItem: null,
};

const selectedPatientSlice = createSlice({
  name: "patientSelection",
  initialState,
  reducers: {
    setSelectedPatient(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload;
    },
    clearSelectedPatient(state) {
      state.selectedItem = null;
    },
  },
});

export const { setSelectedPatient, clearSelectedPatient } =
  selectedPatientSlice.actions;
export default selectedPatientSlice.reducer;
